import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import VendorLogo from '../atoms/VendorLogo';

const Holder = styled.div`
  border-bottom: 1px dotted ${props => props.theme.colours.darkGrey};
  padding: 2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  text-decoration: none;
  grid-gap: 0.5rem;
  align-items: center;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 5fr 1fr 2fr 2fr;
    grid-gap: 2rem;
  }
  h3, p, small {
    margin: 0;
  }
`;

const Cell = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  @media( ${props => props.theme.breakpoints.md} ) {
    display: block;
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  a { text-decoration: none; }
`;

const TitleHolder = styled.small`
  display: block;
  @media( ${props => props.theme.breakpoints.md} ) {
    display: none;
  }
`;

const LogoHolder = styled.div`
  display: none;
  @media( ${props => props.theme.breakpoints.md} ) {
    display: block;
  }
`;

function TrainingPreview( { date, allTraining } ) {

  const [ course, setCourse ] = useState( null );

  useEffect( () => {
    const result = allTraining.nodes.filter( node => {
      if ( node.dates ) {
        return node.dates.filter( dateNode => dateNode.id === date.id ).length > 0;
      } else {
        return null;
      }
    } );

    if ( result.length > 0 ) {
      setCourse( result[ 0 ] );
    }
  }, [ date, allTraining ] );

  if ( !course ) {
    return null;
  }

  return (
    <Holder>
      <Cell show>{course && course.vendor && course.vendor[ 0 ] &&
        <>
          <TitleHolder>{course.vendor[ 0 ].title}</TitleHolder>
          <LogoHolder>
            <VendorLogo base="vendor-training" vendor={course.vendor[ 0 ]}/>
          </LogoHolder>
        </>
        }
      </Cell>
      <Cell show>
        <Link to={`/training/${course && course.slug}`}>
          <h3 className="p">{course && course.title}</h3>
        </Link>
      </Cell>
      <Cell show>
        <Link to={`/training/${course && course.slug}`}>
          <p className="small">{course && course.duration}</p>
        </Link>
      </Cell>
      <Cell show={date.date}>
        <Link to={`/training/${course && course.slug}`}>
          <small>{date.date}</small>
        </Link>
      </Cell>
      <Cell show={date.location}>
        <Link to={`/training/${course && course.slug}`}>
          <small>{date.location}</small>
        </Link>
      </Cell>
    </Holder>
  )
}

TrainingPreview.propTypes = {
  date: PropTypes.shape( {
    id: PropTypes.string,
    date: PropTypes.string,
    location: PropTypes.string,
  } ).isRequired,
  allTraining: PropTypes.object,
};
export default TrainingPreview;
