import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/molecules/SEO';
import PreHead from '../components/molecules/PreHead';
import Container from '../components/atoms/Container';
import TrainingListHeader from '../components/molecules/TrainingListHeader';
import TrainingPreview from '../components/molecules/TrainingPreview';
import VendorLogo from '../components/atoms/VendorLogo';

const Holder = styled.div`
  margin: 6rem 0 10rem 0;
`;


const TitleHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;
  h1 {
    margin: 0;
  }
`;


class VendorTraining extends Component {
  render() {

    const { contentfulVendor, allContentfulTraining, allContentfulTrainingDate  } = this.props.data;
    const { title, description, slug } = contentfulVendor;

    return (
      <>
        <SEO title={title} description={description}/>
        <PreHead image="hero_00002.jpg"/>
        <Container>
          <Holder>
            <TitleHolder>
              <VendorLogo vendor={contentfulVendor} showTitle={false}/>
              <h1>{title} Training</h1>
              <small><Link to={`/vendor/${slug}`}>View {title} products &rarr;</Link></small>
            </TitleHolder>
            <TrainingListHeader/>
            {allContentfulTrainingDate && allContentfulTrainingDate.nodes.map( node => (
              <TrainingPreview key={node.id} date={node} allTraining={allContentfulTraining}/>
            ) )}
          </Holder>
        </Container>
      </>
    )
  }
}

export default VendorTraining;

export const pageQuery = graphql`
    query($id: String!, $dates: [String!]!) {
        contentfulVendor(id: { eq: $id }) {
            title
            description
            slug
            logo {
                fluid(maxWidth: 1000) {
                    sizes
                    src
                    srcSet
                    aspectRatio
                }
            }
        }
        allContentfulTrainingDate(
            sort: {fields: [date], order: ASC}
            filter: {id: {in: $dates}}
        ) {
            nodes {
                id
                date(formatString: "DD.MM.YY")
                location
            }
        }
        allContentfulTraining {
            nodes {
                id
                title
                slug
                duration
                availableOnDemand
                excerpt {
                    excerpt
                }
                vendor{
                    title
                    logo {
                        fluid(maxWidth: 500) {
                            base64
                            sizes
                            src
                            srcSet
                            aspectRatio
                        }
                    }
                    slug
                }
                dates {
                    id
                }
            }
        }
    }
`;
