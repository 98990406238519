import React, { Component } from 'react';
import styled from 'styled-components';

const Holder = styled.div`
  display: none;
  @media( ${props => props.theme.breakpoints.md } ) {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr 2fr 2fr;
    padding: 1rem 0;
    grid-gap: 2rem;
    border-bottom: 1px solid;
  }
`;

class TrainingListHeader extends Component {
  render() {
    return (
      <Holder>
        <small>Vendor</small>
        <small>Title</small>
        <small>Duration</small>
        <small>Date</small>
        <small>Location</small>
      </Holder>
    )
  }
}

export default TrainingListHeader;
